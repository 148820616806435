body {
    margin: 15px;
    padding: 0;
    font-family: "Rubik", sans-serif;
    background-color: #f8f8f8;
    font-size: 14px;
}

h1,
h2,
h3,
h4,
p,
li,
div,
a,
.ui.header,
input[type="text"],
input[type="placeholder"],
.input,
.ui.input input[type="text"] {
    font-family: "Rubik", sans-serif;
    /* color: #d4e4e4e; */
}

.active,
.item {
    color: #4e4e4e;
}
.menu {
    z-index: 10;
}

/* styling for React-Modal */
.ReactModal__Overlay {
    z-index: 1000;
}
/*browser window is 600px wide or less:*/
@media only screen and (max-width: 1000px) {
    .menu {
        display: none;
    }
}


.touchable-highlight {
    cursor: pointer;
    user-select: none;
    -webkit-transition: all 100ms ease;
    -moz-transition: all 100ms ease;
    -o-transition: all 100ms ease;
    transition: all 100ms ease;
}

.touchable-highlight:hover {
    opacity: 0.8;
    background-color: rgba(0, 0, 0, 0.1);
}


textarea {
    width: 500px;
    height: 100px;
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background: none repeat scroll 0 0 #f7f8f8;
    border-color: #f8f8f8;
    border-image: none;
    border-radius: 3px 3px 3px 3px;
    border-style: none solid solid none;
    border-width: medium 1px 1px medium;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12) inset;
    color: #555555;
    font-size: 1em;
    line-height: 1.4em;
    padding: 5px 8px;
    transition: background-color 0.2s ease 0s;
}

textarea:focus {
    /*background: none repeat scroll 0 0 #ffffff;*/
    outline-width: 0;
}

button {
    cursor: pointer;
    /* background-color: rgb(240, 240, 240); */
    border-radius: 3px;
    border-width: 0px;
    margin: 5px;
    font-size: 12px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.05);
    padding: 7px;
    padding-left: 10px;
    padding-right: 10px;
    -webkit-transition: all 100ms ease;
    -moz-transition: all 100ms ease;
    -o-transition: all 100ms ease;
    transition: all 100ms ease;
    outline: none;
    opacity: 1;
    user-select: none;
}
button:hover {
    /* background-color: rgb(230, 230, 230); */
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.15);
}
button:active {
    opacity: 0.8;
    /* background-color: rgb(230, 230, 230); */
}

input {
    border-radius: 2px;
    padding: 10px;
    border: solid 1px gainsboro;
    -webkit-transition: box-shadow 0.3s, border 0.15;
    -moz-transition: box-shadow 0.15, border 0.15;
    -o-transition: box-shadow 0.15, border 0.15;
    transition: box-shadow 0.15, border 0.15;
}
input:focus {
    outline: none;
}
input:focus,
input.focus {
    border: solid 1px #efefef;
    -webkit-box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
}

.css-10nd86i{
    min-width: 180px;
    margin:10px
}

.toggle-switch>input:checked+.slider:before {
    transform: translate(20px,-50%);
    background-color:#5fc990
}

/* STYLING FOR react-table LIBRARY */
.ReactTable .-header .rt-th {
    padding: 10px !important;
    background-color: #f5f5f5;
}
.ReactTable .-header .rt-resizable-header {
    border: none !important;
}
.ReactTable .rt-thead.-header {
    box-shadow: none !important;
}

.promo-blurb  td,th{
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.snackpass__react-select {
    width: 200px;
}

.snackpass__react-select_big {
    width: 600px;
}

.snackpass__disabled {
    cursor: no-drop;
}

.scrollable-x {
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    transform: translate3d(0, 0, 0);
}
.hidden-scroll-x {
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
}
.hidden-scroll-x::-webkit-scrollbar {
	display: none !important;
	width: 0 !important;
	overflow-x: -moz-scrollbars-none;
	-ms-overflow-style: none;
}

.snackpass__search-input {
    border: none !important;
    box-shadow: none !important;
    width: 100%;
    border-bottom: 1px solid #ccc !important;
    border-radius: 0;
    padding: 5px 5px;
    background-color: #ffffff;
}
.snackpass__search-input input {
    border: none !important;
    box-shadow: none !important;
    font-size: 16px;
}

.snackpass__input-container input::placeholder {
    color: #ccc;
}

.snackpass__table {
    border: none;
}

.snackpass__table .rt-thead .rt-tr {
    border-top: 1px solid #efefef !important;
    border-bottom: 1px solid #efefef !important;
}

.snackpass__table .rt-thead .rt-th {
    background-color: #f7f8fa !important;
}

.snackpass__table .rt-tbody .rt-tr {
    align-items: center !important;
}

.snackpass__table .rt-tbody .rt-td {
    border-right: none;
}

.snackpass__table.snackpass__child-table {
    background-color: #f7f8fa; 
}